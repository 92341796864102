export const BACKEND_URL = "https://billing.hngpl.org/hngpl-api"

// export const BACKEND_URL = "http://localhost:9190/hngpl-api"

export const USER_TYPE_ROUTES = {
    "admin" : [
      "/dashboard",
      "/tables/business-partner",
      "/tables/meter-reader",
      "/tables/billed-business-partners",
      "/tables/unbilled-business-partners",
      // "/tables/billing-cycle-terms",
      "/tables/monthly",
      "/tables/bp-ledger",
      "/tables/current-outstandings",
      "/tables/billing-ledger",
      "/tables/sales-report",
      "/tables/sales-summary",
      "/tables/sales-cycle-bp-wise",
      "/tables/provisional-billing",
      // "/actions/receive-payments",
      "/crm/receive-payments",
      "/actions/verify-payments",
      "/actions/tariff-settings",
      "/actions/add-meter-reader",
      "/actions/indents",
      "/actions/payment-reversal",
      // "/tools/view-consumer-bills",
      "/crm/view-consumer-bills",
      "/tools/online-payment-reconciliation",
      // "/tools/new-connection",
      "/crm/new-connection",
      "/tools/onboard-process",
      "/tools/sms-notifications",
      "/tables/bp-outstandings",
      "/tables/sms-summary",
      // "/tables/bp-individual-ledger",
      "/crm/bp-individual-ledger",
      "/tables/sales-cycle-wise",
      "/actions/bp-change-status",
      "/actions/bp-change-scheme",
      "/actions/bp-change-outstanding",
      "/actions/bp-change-meter",
      "/tables/price-report",
      "/actions/schemes",
      "/tables/security-deposits",
      "/tools/invoice-regeneration",
      "/tables/transaction-ledger",
      "/tables/bp-changes-history",
      "/tables/tds-retained-report",
      "/crm/new-grievance",
      "/crm/manage-grievance",
      "/tables/onboard",
    ],
    "MeterReader" : [
      
    ],
    "Accounts" : [
      "/actions/verify-payments",
    ],
    "BillCollection" : [
      // "/actions/receive-payments",
      "/crm/receive-payments",
      "/actions/verify-payments",
    ],
    "mr1" : [
      // "/actions/receive-payments",
      "/crm/receive-payments",
    ],
    "mr2" : [
      // "/actions/receive-payments",
      "/crm/receive-payments",
    ]
  }