import React, { Component,Suspense, lazy } from 'react';
import { Routes, Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';


const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const BusinessPartnerReport = lazy(() => import('./tables/BusinessPartnerReport'));
const BilledBusinessPartnerReport = lazy(() => import('./tables/BilledBusinessPartnerReport'));
const UnbilledBusinessPartnerReport = lazy(() => import('./tables/UnbilledBusinessPartnerReport'));
const BillingCycleTerms = lazy(() => import('./tables/BillingCycleTerms'));
const IframePage = lazy(()=> import('./tables/IframePage'));

const ReceivePayments = lazy(() => import('./actions/ReceivePayments'));
const VerifyPayments = lazy(() => import('./actions/VerifyPayments'));
const EditPayment = lazy(() => import('./actions/EditPayment'));
const TariffSettings = lazy(() => import('./actions/TariffSettings'));
const MeterReaderReport = lazy(() => import('./tables/MeterReaderReport'));
const AddMeterReader = lazy(() => import('./actions/AddMeterReader'));
const MeterReaderColonies = lazy(() => import('./actions/MeterReaderColonies'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));
const Settings = lazy(() => import('./user-pages/Settings'));

const ManageTickets = lazy(() => import('./tables/ManageTickets'));

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/dashboard" component={ Dashboard } />

          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />

          <Route exact path="/tables/basic-table" component={ BasicTable } />
          <Route exact path="/tables/business-partner" component={ BusinessPartnerReport } />
          <Route exact path="/tables/meter-reader" component={ MeterReaderReport } />
          <Route exact path="/tables/billed-business-partners" component={ BilledBusinessPartnerReport } />
          <Route exact path="/tables/unbilled-business-partners" component={ UnbilledBusinessPartnerReport } />
          <Route exact path="/tables/billing-cycle-terms" component={ BillingCycleTerms } />
          <Route exact path="/tables/monthly" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/indent_summary.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/bp-ledger" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/ledger.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/current-outstandings" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/outstanding.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/billing-ledger" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/billing.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/sales-report" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/sales.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/sales-summary" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/summary.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/bp-outstandings" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/outstanding_bp.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/sms-summary" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/sms_summary.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/bp-individual-ledger" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/passbook.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/sales-cycle-wise" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/sales_cycle.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/sales-cycle-bp-wise" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/sales_cycle_bp.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/provisional-billing" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/provisional.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/price-report" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/action/price_report.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/security-deposits" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/deposits.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/transaction-ledger" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/trans_ledger.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/bp-changes-history" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/bp_change_history.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/tds-retained-report" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/tds.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tables/onboard" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/onboard.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          
          {/* <Route exact path="/tools/view-consumer-bills" component={IframePage}/> */}
          <Route exact path="/tools/view-consumer-bills" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/v/index.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tools/online-payment-reconciliation" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/payments/index.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tools/new-connection" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/new/index.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tools/onboard-process" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/manage/index.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tools/sms-notifications" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/sms/index.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/tools/invoice-regeneration" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/tools/invoice_regen.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          
          <Route exact path="/actions/receive-payments" component={ ReceivePayments } />
          <Route exact path="/actions/verify-payments" component={ VerifyPayments } />
          <Route exact path="/actions/edit-payment/:id" component={ EditPayment } />

          <Route exact path="/actions/tariff-settings" component={ TariffSettings } />
          <Route exact path="/actions/add-meter-reader" component={ AddMeterReader } />
          <Route exact path="/actions/edit-meter-reader" component={ AddMeterReader } />
          <Route exact path="/actions/meter-reader-colonies" component={ MeterReaderColonies } />
          <Route exact path="/actions/indents" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/indent.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/actions/bp-change-status" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/action/change_status.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/actions/bp-change-scheme" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/action/change_scheme.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/actions/bp-change-outstanding" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/action/change_outstand.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/actions/bp-change-meter" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/action/change_meter.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/actions/payment-reversal" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/action/payment_reversal.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/actions/schemes" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/action/schemes.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/actions/invoice-regeneration" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/tools/invoice_regen.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>

          <Route exact path="/crm/view-consumer-bills" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/v/index.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/crm/new-connection" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/new/index.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/crm/bp-individual-ledger" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/reports/passbook.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/crm/receive-payments" component={ ReceivePayments } />
          <Route exact path="/crm/new-grievance" render={(props)=><IframePage {...props} src={"https://cs.hngpl.org/ticket/new.php?user_id="+localStorage.getItem("UserId")}></IframePage>}/>
          <Route exact path="/crm/manage-grievance" component={ ManageTickets }/>


          <Route exact path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route exact path="/icons/mdi" component={ Mdi } />

          <Route exact path="/charts/chart-js" component={ ChartJs } />


          <Route exact path="/user-pages/login-1" component={ Login } />
          <Route exact path="/user-pages/register-1" component={ Register1 } />
          <Route exact path="/user-pages/settings" component={ Settings } />

          <Route exact path="/error-pages/error-404" component={ Error404 } />
          <Route exact path="/error-pages/error-500" component={ Error500 } />

          <Redirect exact to="/user-pages/login-1" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;