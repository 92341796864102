import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
// import * as Constants from '../Constants';

class Sidebar extends Component {
  state = {};

  // userTypeRoutes = Constants.USER_TYPE_ROUTES
  userTypeRoutes = JSON.parse(localStorage.getItem("routes"))

  toggleMenuState(menuState) {
    
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  isMainMenuVisible(userType, route) {
    if(this.userTypeRoutes && this.userTypeRoutes[userType]) {
      var isVisible = this.userTypeRoutes[userType].some(r => {return r.startsWith(route)})
      return isVisible;
    }
  }

  isSubMenuVisible(userType, route) {
    if(this.userTypeRoutes && this.userTypeRoutes[userType]) {
      var isVisible = this.userTypeRoutes[userType].some(r => {return r===route})
      return isVisible;
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
      {path:'/actions', state: 'actionsPagesMenuOpen'},
      {path:'/tools', state: 'toolsPagesMenuOpen'},
      {path:'/crm', state: 'crmPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  } 

  gotoSettings = (e) => {
    e.preventDefault();
    this.props.history.push("/user-pages/settings")
  }
  logout = (e) => {
    e.preventDefault();
    localStorage.removeItem('Username')
    localStorage.removeItem('UserType')
    localStorage.removeItem('UserId')
    this.props.history.push("/user-pages/login-1")

  }
  render () {
    // console.log(localStorage.getItem('UserType'));
    // console.log(this.state.username)
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar" 
      // style={{position:"fixed"}}
      >
        <ul className="nav">
          <li className="nav-item nav-category"><Trans>&nbsp;</Trans></li>
          {
          localStorage.getItem('UserType') && this.isMainMenuVisible(localStorage.getItem('UserType'),"/dashboard") ?
          <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <span className="icon-bg"><i className="mdi mdi-cube menu-icon"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          :<></>
          }
          
          {
            localStorage.getItem('UserType') && this.isMainMenuVisible(localStorage.getItem('UserType'),"/tables") ?
          <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <span className="icon-bg"><i className="mdi mdi-table-large menu-icon"></i></span>
              <span className="menu-title"><Trans>Reports</Trans></span>
              <i className="menu-arrow"></i>
            </div>

            {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/business-partner") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/business-partner') ? 'nav-link active' : 'nav-link' } to="/tables/business-partner"><Trans>Business Partner Report</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
            }
            {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/meter-reader") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/meter-reader') ? 'nav-link active' : 'nav-link' } to="/tables/meter-reader"><Trans>Meter Reader Report</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
            }
            {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/billed-business-partners") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/billed-business-partners') ? 'nav-link active' : 'nav-link' } to="/tables/billed-business-partners"><Trans>Billed Business Partners</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/unbilled-business-partners") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/unbilled-business-partners') ? 'nav-link active' : 'nav-link' } to="/tables/unbilled-business-partners"><Trans>Unbilled Business Partners</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }

          

          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/monthly") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/monthly') ? 'nav-link active' : 'nav-link' } to="/tables/monthly"><Trans>Indents Summary</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/bp-ledger") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/bp-ledger') ? 'nav-link active' : 'nav-link' } to="/tables/bp-ledger"><Trans>BP Ledger</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/current-outstandings") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/current-outstandings') ? 'nav-link active' : 'nav-link' } to="/tables/current-outstandings"><Trans>Outstanding Summary</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/billing-ledger") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/billing-ledger') ? 'nav-link active' : 'nav-link' } to="/tables/billing-ledger"><Trans>Billing Ledger</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/sales-report") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/sales-report') ? 'nav-link active' : 'nav-link' } to="/tables/sales-report"><Trans>Sales Report</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/sales-summary") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/sales-summary') ? 'nav-link active' : 'nav-link' } to="/tables/sales-summary"><Trans>Sales Summary</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/bp-outstandings") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/bp-outstandings') ? 'nav-link active' : 'nav-link' } to="/tables/bp-outstandings"><Trans>BP Outstandings</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/sms-summary") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/sms-summary') ? 'nav-link active' : 'nav-link' } to="/tables/sms-summary"><Trans>SMS Summary</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/bp-individual-ledger") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/bp-individual-ledger') ? 'nav-link active' : 'nav-link' } to="/tables/bp-individual-ledger"><Trans>BP Individual Ledger</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/sales-cycle-wise") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/sales-cycle-wise') ? 'nav-link active' : 'nav-link' } to="/tables/sales-cycle-wise"><Trans>Sales Cycle wise</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/sales-cycle-bp-wise") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/sales-cycle-bp-wise') ? 'nav-link active' : 'nav-link' } to="/tables/sales-cycle-bp-wise"><Trans>Sales Cycle BP wise</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/provisional-billing") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/provisional-billing') ? 'nav-link active' : 'nav-link' } to="/tables/provisional-billing"><Trans>Provisional Utilization</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/price-report") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/price-report') ? 'nav-link active' : 'nav-link' } to="/tables/price-report"><Trans>Gas Price Report</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/security-deposits") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/security-deposits') ? 'nav-link active' : 'nav-link' } to="/tables/security-deposits"><Trans>Security Deposits</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/transaction-ledger") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/transaction-ledger') ? 'nav-link active' : 'nav-link' } to="/tables/transaction-ledger"><Trans>Ledger History</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/bp-changes-history") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/bp-changes-history') ? 'nav-link active' : 'nav-link' } to="/tables/bp-changes-history"><Trans>BP Changes History</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/tds-retained-report") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/tds-retained-report') ? 'nav-link active' : 'nav-link' } to="/tables/tds-retained-report"><Trans>TDS Retained Report</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tables/onboard") ?
            <Collapse in={ this.state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tables/onboard') ? 'nav-link active' : 'nav-link' } to="/tables/onboard"><Trans>Onboard</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
        </li>
        :
        <></>
        }

        {
        localStorage.getItem('UserType') && this.isMainMenuVisible(localStorage.getItem('UserType'),"/actions") ?
        <li className={ this.isPathActive('/actions') ? 'nav-item active' : 'nav-item' }>
          <div className={ this.state.actionsPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('actionsPagesMenuOpen') } data-toggle="collapse">
            <span className="icon-bg"><i className="mdi mdi-table-large menu-icon"></i></span>
            <span className="menu-title"><Trans>Actions</Trans></span>
            <i className="menu-arrow"></i>
          </div> 
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/receive-payments") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/receive-payments') ? 'nav-link active' : 'nav-link' } to="/actions/receive-payments"><Trans>Receive Payments</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/verify-payments") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/verify-payments') ? 'nav-link active' : 'nav-link' } to="/actions/verify-payments"><Trans>Verify Payments</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/tariff-settings") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/tariff-settings') ? 'nav-link active' : 'nav-link' } to="/actions/tariff-settings"><Trans>Tariff Settings</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/indents") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/indents') ? 'nav-link active' : 'nav-link' } to="/actions/indents"><Trans>Indents</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/bp-change-status") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/bp-change-status') ? 'nav-link active' : 'nav-link' } to="/actions/bp-change-status"><Trans>BP Change Status</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/bp-change-scheme") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/bp-change-scheme') ? 'nav-link active' : 'nav-link' } to="/actions/bp-change-scheme"><Trans>BP Change SD/Scheme</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/bp-change-outstanding") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/bp-change-outstanding') ? 'nav-link active' : 'nav-link' } to="/actions/bp-change-outstanding"><Trans>BP Change Outstand</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/bp-change-meter") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/bp-change-meter') ? 'nav-link active' : 'nav-link' } to="/actions/bp-change-meter"><Trans>BP Change Meter</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/payment-reversal") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/payment-reversal') ? 'nav-link active' : 'nav-link' } to="/actions/payment-reversal"><Trans>Payment Reversal</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/schemes") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/schemes') ? 'nav-link active' : 'nav-link' } to="/actions/schemes"><Trans>Schemes</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/actions/invoice-regeneration") ?
            <Collapse in={ this.state.actionsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/actions/invoice-regeneration') ? 'nav-link active' : 'nav-link' } to="/actions/invoice-regeneration"><Trans>Invoice Regeneration</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
        </li>
        :<></>
        }

        {
        localStorage.getItem('UserType') && this.isMainMenuVisible(localStorage.getItem('UserType'),"/tools") ?
        <li className={ this.isPathActive('/tools') ? 'nav-item active' : 'nav-item' }>
          <div className={ this.state.toolsPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('toolsPagesMenuOpen') } data-toggle="collapse">
            <span className="icon-bg"><i className="mdi mdi-table-large menu-icon"></i></span>
            <span className="menu-title"><Trans>Tools</Trans></span>
            <i className="menu-arrow"></i>
          </div> 
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tools/online-payment-reconciliation") ?
            <Collapse in={ this.state.toolsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tools/online-payment-reconciliation') ? 'nav-link active' : 'nav-link' } to="/tools/online-payment-reconciliation"><Trans>Online Payment Reconciliation</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tools/new-connection") ?
            <Collapse in={ this.state.toolsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tools/new-connection') ? 'nav-link active' : 'nav-link' } to="/tools/new-connection"><Trans>New Connection</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tools/onboard-process") ?
            <Collapse in={ this.state.toolsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tools/onboard-process') ? 'nav-link active' : 'nav-link' } to="/tools/onboard-process"><Trans>On board Process</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tools/sms-notifications") ?
            <Collapse in={ this.state.toolsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tools/sms-notifications') ? 'nav-link active' : 'nav-link' } to="/tools/sms-notifications"><Trans>SMS Notifications</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {/* {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/tools/invoice-regeneration") ?
            <Collapse in={ this.state.toolsPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/tools/invoice-regeneration') ? 'nav-link active' : 'nav-link' } to="/tools/invoice-regeneration"><Trans>Invoice Regeneration</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          } */}
          
        </li>
        :<></>
        }
        { localStorage.getItem('UserType') && this.isMainMenuVisible(localStorage.getItem('UserType'),"/crm") ?
        <li className={ this.isPathActive('/crm') ? 'nav-item active' : 'nav-item' }>
          <div className={ this.state.crmPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('crmPagesMenuOpen') } data-toggle="collapse">
            <span className="icon-bg"><i className="mdi mdi-table-large menu-icon"></i></span>
            <span className="menu-title"><Trans>CRM</Trans></span>
            <i className="menu-arrow"></i>
          </div> 
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/crm/view-consumer-bills") ?
            <Collapse in={ this.state.crmPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/crm/view-consumer-bills') ? 'nav-link active' : 'nav-link' } to="/crm/view-consumer-bills"><Trans>View Customer Bills</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/crm/new-connection") ?
            <Collapse in={ this.state.crmPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/crm/new-connection') ? 'nav-link active' : 'nav-link' } to="/crm/new-connection"><Trans>New Connection</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/crm/bp-individual-ledger") ?
            <Collapse in={ this.state.crmPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/crm/bp-individual-ledger') ? 'nav-link active' : 'nav-link' } to="/crm/bp-individual-ledger"><Trans>BP Individual Ledger</Trans></Link></li>
              </ul>
            </Collapse>
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/crm/receive-payments") ?
            <Collapse in={ this.state.crmPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/crm/receive-payments') ? 'nav-link active' : 'nav-link' } to="/crm/receive-payments"><Trans>Receive Payments</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/crm/new-grievance") ?
            <Collapse in={ this.state.crmPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/crm/new-grievance') ? 'nav-link active' : 'nav-link' } to="/crm/new-grievance"><Trans>New Grievance</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }
          {
            localStorage.getItem('UserType') && this.isSubMenuVisible(localStorage.getItem('UserType'),"/crm/manage-grievance") ?
            <Collapse in={ this.state.crmPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/crm/manage-grievance') ? 'nav-link active' : 'nav-link' } to="/crm/manage-grievance"><Trans>Manage Grievances</Trans></Link></li>
              </ul>
            </Collapse>  
            :<></>
          }

        </li>
        :<></>
        }
        
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="!#" onClick={this.gotoSettings} className="nav-link"><i className="mdi mdi-settings menu-icon"></i>
                <span className="menu-title"><Trans>Change Password</Trans></span>
              </a>
            </div>
          </li>
          {/* <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="!#" onClick={event => event.preventDefault()} className="nav-link"><i className="mdi mdi-speedometer menu-icon"></i>
                <span className="menu-title"><Trans>Take Tour</Trans></span></a>
            </div>
          </li> */}
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="!#" onClick={this.logout} className="nav-link"><i className="mdi mdi-logout menu-icon"></i>
                <span className="menu-title"><Trans>Log Out</Trans></span></a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {

    // alert(localStorage.getItem('UserType'));
    // alert(this.state.username)
    // console.log(this.state.username)
    // console.log(this.state['username'])

    if(localStorage.getItem('UserType') == null){
      this.props.history.push("/user-pages/login-1")
    }
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);