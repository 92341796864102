import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
ReactDOM.render(
  // <BrowserRouter basename="/">
  //   <App />
  // </BrowserRouter>

  <HashRouter basename="/">
    <App />
  </HashRouter>
, document.getElementById('root'));

serviceWorker.unregister();